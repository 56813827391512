
import { Controller } from "@hotwired/stimulus"
import { MapboxStyleSwitcherControl } from "mapbox-gl-style-switcher";
import mapboxgl from "mapbox-gl"


export default class extends Controller {
  static values = {
    apiKey: String,
    markers: Array,
    stations: Array,
  }

  connect() {
    mapboxgl.accessToken = this.apiKeyValue

    this.map = new mapboxgl.Map({
      container: this.element,
      style: "mapbox://styles/mapbox/streets-v11"
    })
    this.#addMarkersToMap()
    this.#fitMapToMarseille()
    this.#addStationsToMap()
    this.#addNavControl()
    this.#addGeoControl()
    this.#addSwitchControl()
  }

  #addMarkersToMap() {
      this.markersValue.forEach((marker) => {
          const popup = new mapboxgl.Popup().setHTML(marker.info_window)
          const customMarker = document.createElement("div")
          customMarker.setAttribute('style', `background-color:${marker.color}`)
          customMarker.className = `marker ${marker.icon}`
          new mapboxgl.Marker(customMarker)
          .setLngLat([ marker.lng, marker.lat ])
          .setPopup(popup)
          .addTo(this.map)
      });
  }

  #addStationsToMap() {
      const popupStation = (station) => {
        return (
          `<div><h4>Station Atmosud: ${station.name}</h4>
          <h5>Capteurs: ${station.variables}</h5>
          </div>`
        )
      }
      this.stationsValue.forEach((station) => {
          const popup = new mapboxgl.Popup().setHTML(popupStation(station));
          new mapboxgl.Marker()
          .setLngLat([ station.longitude, station.latitude ])
          .setPopup(popup)
          .addTo(this.map)
      });
  }

  #fitMapToMarkers() {
        const bounds = new mapboxgl.LngLatBounds()
        this.markersValue.forEach(marker => bounds.extend([ marker.lng, marker.lat ]))
        this.map.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 0 })
  }

  #fitMapToMarseille() {
        const bbox = [[5.33404, 43.27157],[5.54076, 43.34294]];
        this.map.fitBounds(bbox, { padding: 70, maxZoom: 15, duration: 0 })
  }

  #addGeoControl() {
      const geoControl = new mapboxgl.GeolocateControl({
        positionOptions: {
            enableHighAccuracy: true
        },
        trackUserLocation: true,
        showUserHeading: true
      })
      this.map.addControl(geoControl)
  }

  #addNavControl() {
      const navControl = new mapboxgl.NavigationControl();
      this.map.addControl(navControl)
  }
    
  #addSwitchControl() {
      const styles = [
        {
            title: "Plan",
            uri:"mapbox://styles/mapbox/streets-v11"
        },
        {
            title: "Satellite",
            uri:"mapbox://styles/mapbox/satellite-v9"
        }
      ];
      this.map.addControl(new MapboxStyleSwitcherControl(styles, 'Plan'));
  }
}