import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-form"
export default class extends Controller {
  static targets = [ "form" ]
  static values = {
    filter: Object
  }

  filter() {
      this.formTarget.requestSubmit();
  }
}
